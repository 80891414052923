.body {
  padding: 20px;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.empty h3,h4,h5 {
  color: #bababa;
}
